var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(1),_c('table-bootstrap-basic',{staticClass:"mb-4"}),_vm._m(2),_c('table-bootstrap-row-styling',{staticClass:"mb-4"}),_vm._m(3),_c('table-bootstrap-responsive',{staticClass:"mb-4"}),_vm._m(4),_c('table-bootstrap-stacked',{staticClass:"mb-4"}),_vm._m(5),_c('table-bootstrap-select',{staticClass:"mb-4"}),_vm._m(6),_c('table-bootstrap-row-details',{staticClass:"mb-4"}),_vm._m(7),_c('table-bootstrap-sorting',{staticClass:"mb-4"}),_vm._m(8),_c('table-bootstrap-complete',{staticClass:"mb-4"})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kit__utils__heading"},[_c('h5',[_c('span',{staticClass:"mr-3"},[_vm._v("Bootstrap Tables")]),_c('a',{staticClass:"btn btn-sm btn-light",attrs:{"href":"https://ant.design/components/table","rel":"noopener noreferrer","target":"_blank"}},[_vm._v(" Official Documentation "),_c('i',{staticClass:"fe fe-corner-right-up"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Basic Styling")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Row Styling")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Responsive")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Stacked")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Row Select")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Row details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Sorting")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Complete")])])
}]

export { render, staticRenderFns }